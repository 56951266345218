import { initializeApp, getApps } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';

const firebaseConfig = {
    apiKey: "AIzaSyC70djdByFO-k1gzN7_0_E50Y6O7TL8csE",
    authDomain: "store-front-43e6b.firebaseapp.com",
    projectId: "store-front-43e6b",
    storageBucket: "store-front-43e6b.appspot.com",
    messagingSenderId: "581652415190",
    appId: "1:581652415190:web:219eef6ae204b138e7bf77",
    measurementId: "G-SFW5T12KP9"
};

const app = getApps().length ? getApps()[0] : initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const db = getFirestore(app);
