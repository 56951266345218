import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { onAuthStateChanged } from 'firebase/auth';
import { auth } from '../firebaseServices/firebaseConfig';
import './Home.css';

const Home = () => {
    const navigate = useNavigate();

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
            if (!currentUser) {
                navigate('/login');
            }
        });
        return () => unsubscribe();
    }, [navigate]);

    return (
        <div className="startPage-container">
            <h2>Welcome to Blogster</h2>
            {/* Any other content for the home page */}
        </div>
    );
}

export default Home;
