import { createUserWithEmailAndPassword, signInWithEmailAndPassword, signOut } from "firebase/auth";
import { auth2 } from './firebaseConfigAgile'; // Make sure the correct auth is imported

// Sign UP
export const register = async (email, password) => {
    try {
      const userCredentials = await createUserWithEmailAndPassword(auth2, email, password);
      return userCredentials.user;
    } catch (error) {
        console.error("error signing up: ", error);
        throw error;
    }
};

export const login = async (email, password) => {
    try {
        const userCredentials = await signInWithEmailAndPassword(auth2, email, password);
        return userCredentials.user;
    } catch (error) {
        console.error("error signing in: ", error);
        throw error;
    }
}

export const logout = async () => {
    try {
        await signOut(auth2);
    } catch (error) {
        console.error("Error logging out user:", error);
        throw error;
    }
}
