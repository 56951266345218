export const formatBlogContent = (content) => {
    let formattedContent = content
        .replace(/###\s*(.*)/g, '<h3>$1</h3>')       // Replace ### with <h3> tags
        .replace(/##\s*(.*)/g, '<h2>$1</h2>')        // Replace ## with <h2> tags
        .replace(/#\s*(.*)/g, '<h1>$1</h1>')         // Replace # with <h1> tags
        .replace(/\n\s*\n/g, '<br><br>')             // Replace multiple new lines with <br><br>
        .replace(/\n/g, '<br>');                     // Replace single new lines with <br>
    formattedContent = formattedContent.replace(/\\n\\n/g, '').replace(/\\n/g, '');
    return formattedContent;
};
