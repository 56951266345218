import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { onAuthStateChanged } from 'firebase/auth';
import { collection, addDoc, serverTimestamp, updateDoc, doc, arrayUnion } from 'firebase/firestore';
import { auth, db } from '../firebaseServices/firebaseConfig';
import './BlogStyles.css';
import './BlogsGenerator.css';
import { formatBlogContent } from './contentFormatter.js';
import { FaClipboard, FaClipboardCheck } from 'react-icons/fa';

const predefinedTags = [
  "#Technology", "#Education", "#ComputerScience", "#Tutors", "#MachineLearning",
  "#DigitalCurrencies", "#AI", "#Blockchain", "#EdTech", "#Programming",
  "#DataScience", "#OnlineLearning", "#Crypto", "#STEM", "#VirtualTutoring",
  "#DeepLearning", "#Coding", "#Ecommerce", "#Fintech", "#NeuralNetworks",
  "#Cybersecurity", "#ArtificialIntelligence", "#SmartEducation", "#DigitalTransformation",
  "#BigData", "#Bitcoin", "#Robotics", "#AugmentedReality", "#QuantumComputing",
  "#EdTechInnovation"
];

function BlogsGenerator() {
    const [tone, setTone] = useState('Professional');
    const [topic, setTopic] = useState('Technology');
    const [title, setTitle] = useState('');
    const [authorPhoto, setAuthorPhoto] = useState('');
    const [imageUrl, setImageUrl] = useState('');
    const [output, setOutput] = useState('');
    const [loading, setLoading] = useState(false);
    const [user, setUser] = useState(null);
    const [selectedTag, setSelectedTag] = useState(predefinedTags[0]);
    const [copied, setCopied] = useState(false);
    const [copiedWithTags, setCopiedWithTags] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
            if (currentUser) {
                setUser(currentUser);
            } else {
                navigate('/login');
            }
        });
        return () => unsubscribe();
    }, [navigate]);

    const handleAddBlog = async () => {
        if (!user) {
            alert('You need to be logged in to add a blog.');
            return;
        }
        if (!output) {
            alert('You need to generate a blog before adding it.');
            return;
        }
        const tags = [selectedTag];
        try {
            const blogDocRef = await addDoc(collection(db, 'blogs'), {
                author: user.displayName || user.email,
                authorPhoto: authorPhoto || user.photoURL,
                content: output,
                createdAt: serverTimestamp(),
                imageUrl: imageUrl || '',
                tags: tags,
                title: title || 'Untitled Blog',
                likes: 0
            });
            await updateDoc(doc(db, 'tags', selectedTag), {
                blogs: arrayUnion(blogDocRef.id)
            });
            alert('Blog added successfully!');
        } catch (error) {
            console.error('Error adding blog:', error);
        }
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        setLoading(true);
        setOutput('<p>Generating blog...</p>');

        try {
            const response = await fetch('https://us-central1-store-front-43e6b.cloudfunctions.net/api/generate-blog', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ tone, topic }),
            });

            if (!response.ok) {
                throw new Error('Failed to generate blog');
            }

            const reader = response.body.getReader();
            const decoder = new TextDecoder('utf-8');
            let blogContent = '';

            while (true) {
                const { done, value } = await reader.read();
                if (done) break;
                blogContent += decoder.decode(value, { stream: true });
                const parsedContent = JSON.parse(blogContent).content;
                setOutput(formatBlogContent(parsedContent));
            }

            setLoading(false);
        } catch (error) {
            console.error('Error generating blog:', error);
            setOutput(`<p className="blog-gen-error-message">Error: ${error.message}</p>`);
            setLoading(false);
        }
    };

    const handleCopyToClipboard = () => {
        const tempElement = document.createElement('textarea');
        tempElement.value = output.replace(/<br\s*\/?>/gi, '\n').replace(/<\/?[^>]+(>|$)/g, "");
        document.body.appendChild(tempElement);
        tempElement.select();
        document.execCommand('copy');
        document.body.removeChild(tempElement);
        setCopied(true);
        setTimeout(() => setCopied(false), 2000);
    };

    const handleCopyWithTags = () => {
        const tempElement = document.createElement('textarea');
        tempElement.value = output;
        document.body.appendChild(tempElement);
        tempElement.select();
        document.execCommand('copy');
        document.body.removeChild(tempElement);
        setCopiedWithTags(true);
        setTimeout(() => setCopiedWithTags(false), 2000);
    };

    return (
        <div className="blog-gen-container">
            <div className="blog-gen-content">
                <div className="blog-gen-form-container">
                    <button className="blog-gen-button-add" type="button" onClick={handleAddBlog}>Add the generated blog</button>
                    <form className="blog-gen-form" onSubmit={handleSubmit}>
                        <label htmlFor="blog-gen-tone">Tone:</label>
                        <input type="text" id="blog-gen-tone" value={tone} onChange={(e) => setTone(e.target.value)} required />
                        <label htmlFor="blog-gen-topic">Topic:</label>
                        <input type="text" id="blog-gen-topic" value={topic} onChange={(e) => setTopic(e.target.value)} required />
                        <label htmlFor="blog-gen-title">Title:</label>
                        <input type="text" id="blog-gen-title" value={title} onChange={(e) => setTitle(e.target.value)} required />
                        <label htmlFor="blog-gen-authorPhoto">Author Photo URL:</label>
                        <input type="text" id="blog-gen-authorPhoto" value={authorPhoto} onChange={(e) => setAuthorPhoto(e.target.value)} />
                        <label htmlFor="blog-gen-imageUrl">Blog Image URL:</label>
                        <input type="text" id="blog-gen-imageUrl" value={imageUrl} onChange={(e) => setImageUrl(e.target.value)} />
                        <label htmlFor="blog-gen-tags">Tag:</label>
                        <select id="blog-gen-tags" value={selectedTag} onChange={(e) => setSelectedTag(e.target.value)}>
                            {predefinedTags.map((tag) => (
                                <option key={tag} value={tag}>{tag}</option>
                            ))}
                        </select>
                        <button className="blog-gen-button-generate" type="submit" disabled={loading}>
                            {loading ? <div className="blog-gen-spinner"></div> : 'Generate Blog'}
                        </button>
                    </form>
                </div>
                <div className="blog-gen-output-container">
                    <div className="blog-gen-buttons">
                        <button className="blog-gen-button-copy" type="button" onClick={handleCopyToClipboard}>
                            {copied ? <FaClipboardCheck /> : <FaClipboard />} Copy Text
                        </button>
                        <button className="blog-gen-button-copy" type="button" onClick={handleCopyWithTags}>
                            {copiedWithTags ? <FaClipboardCheck /> : <FaClipboard />} Copy HTML
                        </button>
                    </div>
                    <div className="blog-gen-output" dangerouslySetInnerHTML={{ __html: output }} />
                </div>
            </div>
        </div>
    );
}

export default BlogsGenerator;
