import { initializeApp, getApps } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';

const firebaseConfigAgile = {
    apiKey: "AIzaSyCdPDvp-b-7rG7GvMte07kwFnvNX8pvIxg",
    authDomain: "agilenetics-eef7c.firebaseapp.com",
    projectId: "agilenetics-eef7c",
    storageBucket: "agilenetics-eef7c.appspot.com",
    messagingSenderId: "914973180938",
    appId: "1:914973180938:web:a721abe1a5c708da348bf3",
    measurementId: "G-6MV0NEKXBD"
};

const app2 = getApps().length ? getApps()[0] : initializeApp(firebaseConfigAgile, 'agile');
export const auth2 = getAuth(app2);
export const db2 = getFirestore(app2);
