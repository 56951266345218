import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { onAuthStateChanged } from 'firebase/auth';
import { auth } from '../firebaseServices/firebaseConfig';
import { logout } from '../firebaseServices/authService';
import './NavigationBar.css';

const Navbar = () => {
  const [user, setUser] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      setUser(currentUser);
    });
    return () => unsubscribe();
  }, []);

  const handleLogout = async () => {
    try {
      await logout();
      navigate('/');
    } catch (error) {
      console.error("Error logging out:", error);
    }
  };

  return (
    <nav className="navbar">
      <div className="navbar-container">
        <Link to="/" className="navbar-logo">Blogster</Link>
        <ul className="nav-menu">
          <li className="nav-item">
            <Link to="/blog-list" className="nav-links">Blog List</Link>
          </li>
          <li className="nav-item">
            <Link to="/modify-blog" className="nav-links">Modify a blog</Link>
          </li>
          <li className="nav-item">
            <a href="https://www.agnetportal.com/" className="nav-links">Portal</a>
          </li>
          {user && (
            <li className="nav-item">
              <Link to="/logout" className="nav-links logout-button" onClick={handleLogout}>Logout</Link>
            </li>
          )}
        </ul>
      </div>
    </nav>
  );
}

export default Navbar;
