import React, { useState,useEffect } from 'react';
import { onAuthStateChanged } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';
import { collection, query, where, getDocs, updateDoc, doc } from 'firebase/firestore';
import { db,auth } from '../firebaseServices/firebaseConfig';
import './ModifyBlogs.css';

function ModifyBlog() {
    const [searchField, setSearchField] = useState('author');
    const [searchValue, setSearchValue] = useState('');
    const [blogs, setBlogs] = useState([]);
    const [selectedBlog, setSelectedBlog] = useState(null);
    const [title, setTitle] = useState('');
    const [authorPhoto, setAuthorPhoto] = useState('');
    const [imageUrl, setImageUrl] = useState('');
    const [content, setContent] = useState('');
    const [user, setUser] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
            if (currentUser) {
                setUser(currentUser);
            } else {
                navigate('/login');
            }
        });
        return () => unsubscribe();
    }, [navigate]);

    const handleSearch = async () => {
        let q;
        if (searchField === 'tags') {
            q = query(collection(db, 'blogs'), where(searchField, 'array-contains', `#${searchValue}`));
        } else {
            q = query(collection(db, 'blogs'), where(searchField, '==', searchValue));
        }
        const querySnapshot = await getDocs(q);
        const blogsList = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        setBlogs(blogsList);
    };

    const handleSelectBlog = (blog) => {
        setSelectedBlog(blog);
        setTitle(blog.title);
        setAuthorPhoto(blog.authorPhoto);
        setImageUrl(blog.imageUrl);
        setContent(blog.content);
    };

    const handleUpdateBlog = async () => {
        if (!selectedBlog) return;

        const blogRef = doc(db, 'blogs', selectedBlog.id);
        await updateDoc(blogRef, {
            title,
            authorPhoto,
            imageUrl,
            content
        });
        alert('Blog updated successfully!');
    };

    return (
        <div>
            <h1>Modify Blog</h1>
            {user && <p>Logged in as: {user.email}</p>} 
            <div>
                <label htmlFor="searchField">Search by:</label>
                <select id="searchField" value={searchField} onChange={(e) => setSearchField(e.target.value)}>
                    <option value="author">Author</option>
                    <option value="title">Title</option>
                    <option value="tags">Tags</option>
                </select>
                <input
                    type="text"
                    placeholder={`Enter ${searchField}`}
                    value={searchValue}
                    onChange={(e) => setSearchValue(e.target.value)}
                />
                <button onClick={handleSearch}>Search</button>
            </div>
            <div>
                {blogs.map(blog => (
                    <div key={blog.id} id={`blog-${blog.id}`} className="searchResult" onClick={() => handleSelectBlog(blog)}>
                        <h2>{blog.title}</h2>
                        <p>{blog.author}</p>
                    </div>
                ))}
            </div>
            {selectedBlog && (
                <div>
                    <h2>Modify Blog Details</h2>
                    <label htmlFor="title">Title:</label>
                    <input
                        type="text"
                        id="title"
                        value={title}
                        onChange={(e) => setTitle(e.target.value)}
                    />
                    <br /><br />
                    <label htmlFor="authorPhoto">Author Photo URL:</label>
                    <input
                        type="text"
                        id="authorPhoto"
                        value={authorPhoto}
                        onChange={(e) => setAuthorPhoto(e.target.value)}
                    />
                    <br /><br />
                    <label htmlFor="imageUrl">Blog Image URL:</label>
                    <input
                        type="text"
                        id="imageUrl"
                        value={imageUrl}
                        onChange={(e) => setImageUrl(e.target.value)}
                    />
                    <br /><br />
                    <label htmlFor="content">Content:</label>
                    <textarea
                        id="content"
                        value={content}
                        onChange={(e) => setContent(e.target.value)}
                    />
                    <br /><br />
                    <button onClick={handleUpdateBlog}>Update Blog</button>
                </div>
            )}
        </div>
    );
}

export default ModifyBlog;
