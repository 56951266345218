import React, { useEffect, useState } from 'react';
import { collection, getDocs, doc, updateDoc } from 'firebase/firestore';
import { db } from '../firebaseServices/firebaseConfig';
import './BlogList.css'; 
import { formatBlogContent } from './contentFormatter.js'; 


const BlogList = () => {
  const [blogs, setBlogs] = useState([]);

  useEffect(() => {
    const fetchBlogs = async () => {
      const blogsCollection = collection(db, "blogs");
      const blogsSnapshot = await getDocs(blogsCollection);
      const blogsList = blogsSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setBlogs(blogsList);
    };
    fetchBlogs();
  }, []);

  const handleLike = async (blogID, currentLikes) => {
    try {
      const blogRef = doc(db, "blogs", blogID);
      await updateDoc(blogRef, {
        likes: currentLikes + 1
      });

      setBlogs(blogs.map(blog =>
        blog.id === blogID ? { ...blog, likes: currentLikes + 1 } : blog
      ));
    } catch (error) {
      console.error("Error adding likes: ", error);
    }
  };

  return (
    <div className="blog-list-container">
      <div className="blog-list">
        {blogs.map(blog => (
          <BlogCard key={blog.id} blog={blog} onLike={handleLike} />
        ))}
      </div>
    </div>
  );
};

const BlogCard = ({ blog, onLike }) => {
  const createdAt = blog.createdAt?.seconds ? new Date(blog.createdAt.seconds * 1000).toLocaleString() : 'Unknown date';
  const tags = Array.isArray(blog.tags) ? blog.tags : [];

  return (
    <div className="blog-card">
      <h2>{blog.title}</h2>
      <div className="author-info">
        <img src={blog.authorPhoto} alt="" width={50} height={50} />
        <p>{blog.author}</p>
      </div>
      <img src={blog.imageUrl} alt={blog.title} width="100%" />
      <div id="output" dangerouslySetInnerHTML={{ __html: formatBlogContent(blog.content) }} />
      <div className="tags">
        {tags.map(tag => <span key={tag} className="tag">{`#${tag}`}</span>)}
      </div>
      <p>Created at: {createdAt}</p>
      <button onClick={() => onLike(blog.id, blog.likes)}>Like ({blog.likes || 0})</button>
    </div>
  );
};

export default BlogList;
